import axiosInstance from "./interceptor";

export const verifyToken = async (data) => {
  try {
    const result = await axiosInstance.post("/auth/verifyToken", data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const sendOtp = async function (data) {
  try {
    const result = await axiosInstance.post("/otp/send", data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const verifyOtp = async function (token) {
  try {
    const result = await axiosInstance.post("/otp/loginVerify", token);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const addUserDetails = async function (data) {
  try {
    const result = await axiosInstance.patch("/user/update", data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getUserByToken = async function (data) {
  try {
    const result = await axiosInstance.get("/user");
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const uploadFile = async function (data) {
  try {
    const result = await axiosInstance.post("/file/upload", data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const addPreferences = async function (data) {
  try {
    const result = await axiosInstance.post("/preference/add", data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updatePreferences = async function (data) {
  try {
    const result = await axiosInstance.patch("/preference/update", data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUserPreferences = async () => {
  try {
    const result = await axiosInstance.get("/preference/userPreference");
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const markProfileComplete = async function (data) {
  try {
    const result = await axiosInstance.patch("/user/markProfileComplete", data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const eventsListing = async function () {
  try {
    const result = await axiosInstance.get(
      "/jambase/events?page=1&limit=10&eventType=festival"
    );
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const genereListing = async function (data) {
  try {
    const result = await axiosInstance.get("/jambase/genere");
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};
//genere of user when is login
export const genereListingPostLogin = async function (data) {
  try {
    const result = await axiosInstance.get(`/jambase/genere?user_id=${data}`);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const eventDetails = async function (identifier) {
  try {
    const result = await axiosInstance.get(
      `/jambase/event?eventId=${identifier}`
    );
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getTribes = async function (data) {
  try {
    const result = await axiosInstance.post(`/tribe/getTribes`, data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const joinTribes = async function (data) {
  try {
    const result = await axiosInstance.post(`/tribe/join`, data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const pastSuggestion = async function (search_query) {
  try {
    const result = await axiosInstance.get(
      `/tribe/pastMembers?search_query=${search_query}`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const preLoginEvent = async (data) => {
  try {
    const result = await axiosInstance.post("/jambase/dashboardEvents", data);
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postLoginEvents = async (data) => {
  try {
    const result = await axiosInstance.post(
      `/jambase/eventsOnUserPreference`,
      data
    );
    return result;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const inviteTribes = async function (data) {
  try {
    const result = await axiosInstance.post(`/tribe/sendInvitation`, data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const cretaeTribes = async function (data) {
  try {
    const result = await axiosInstance.post(`/tribe/create`, data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const usersTribes = async function (query) {
  try {
    const result = await axiosInstance.get(
      `tribe/userTribes?page=${query.page}&limit=${query.limit}&search_query=${query.search_query}&sort_by=${query.sort_by}&order=${query?.order}`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getTribe = async function (tribeID) {
  try {
    const result = await axiosInstance.get(`tribe/getTribe/${tribeID}`);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getMessages = async function (messageData) {
  try {
    const result = await axiosInstance.post(
      `message/getMessages/`,
      messageData
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const tribeAndUserCountOfEvent = async (event_id) => {
  try {
    const result = await axiosInstance.get(
      `/tribe/eventTribes?event_id=${event_id}`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const userPastAndUpComingEvents = async ({
  page,
  limit,
  sort_by,
  order,
  search_query,
}) => {
  try {
    const result = await axiosInstance.get(
      `/user/pastAndUpcomingEvents?page=${page}&limit=${limit}&sort_by=${sort_by}&order=${order}&search_query=${search_query}`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const SendMessages = async function (data) {
  try {
    const result = await axiosInstance.post(`message/send/`, data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getReportReasons = async () => {
  try {
    const result = await axiosInstance.get(`/tribe/blockReasons`);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const blockUser = async (data) => {
  try {
    const result = await axiosInstance.post(`/tribe/blockUser`, data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const reportUser = async (data) => {
  try {
    const result = await axiosInstance.post(`/tribe/reportUser`, data);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const exitTribe = async ({ tribe_id, user_id }) => {
  try {
    const result = await axiosInstance.delete(
      `/tribe/exit?tribe_id=${tribe_id}&user_id=${user_id}`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getTribeUsersLocations = async (tribe_id) => {
  try {
    const result = await axiosInstance.get(
      `message/participantsLocations?tribe_id=${tribe_id}`
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 *
 * @param {message_id, poll_option_index} data
 */
export const makeVoteForPollOption = async (data) => {
  try {
    const result = await axiosInstance.post(
      `message/makeVoteForPollOption`,
      data
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 *
 * @param {string} messageId
 */
export const getMessage = async (messageId) => {
  try {
    const result = await axiosInstance.get(`message/get/${messageId}`);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

/**
 *
 * @param {tribe_id, is_location_sharing_enabled} data
 * @returns
 */
export const turnOnOffLocationSharingForAllTribeMembers = async (data) => {
  try {
    const result = await axiosInstance.patch(
      `tribe/turnOnOffLocationSharing`,
      data
    );
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getUserById = async function (user_id) {
  try {
    const result = await axiosInstance.get(`/user/get/${user_id}`);
    return result.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const hideLocation = async (payload) => {
  try {
    const result = await axiosInstance.post(
      "/user/hideMyLocationFromUser",
      payload
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

//Unhide location from the user
export const UnhideLocation = async (payload) => {
  try {
    const result = await axiosInstance.post(
      "/user/unhideMyLocationFromUser",
      payload
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

// Api to share feedback
export const shareFeedback = async (payload) => {
  try {
    const result = await axiosInstance.post("/user/feedback/add", payload);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

//Api for search events
export const searchEvents = async (payload) => {
  try {
    const result = await axiosInstance.post(`/jambase/events`, payload);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
/**
 *
 * @param {string} user_id
 * @param {string} firebase_token
 * @returns
 */

export const addFirebaseToken = async (user_id, firebase_token) => {
  try {
    const result = await axiosInstance.patch("/auth/setDeviceToken", {
      user_id,
      firebase_token,
    });
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const readMessages = async (payload) => {
  try {
    const result = await axiosInstance.post("/message/readMessages", {
      messages: payload,
    });
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const getTicketOfEvent = async (payload) => {
  try {
    const result = await axiosInstance.get(
      `/jambase/event?event_id=${payload.event_id}`
    );
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
