import { Navigate, useNavigate } from "react-router-dom";
import { cretaeTribes } from "../../api/api";
import { Button } from "../../Component/shared/Button";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { useDispatch } from "react-redux";
import {
  closeCreateTribe,
  closeTribe,
} from "../../redux/Reducers/modalReducer";
import { CheckmarkBadge01Icon } from "hugeicons-react";
import OfficialTribe from "./OfficialTribe";

interface Props {
  eventId: string;
}

function FeaturedTribe(props: Props) {
  const { eventId } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate } = useMutation({
    mutationFn: () => {
      return cretaeTribes({
        name: "Official Tribe",
        event_id: eventId,
      });
    },
    onSuccess: (response) => {
      console.log("Tribe created", response);
      dispatch(closeTribe());
      return navigate(`/myTribes`);
    },
  });

  return (
    <div className="">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="space-y-2 text-left w-full flex items-center gap-3">
          <div className="">
          <OfficialTribe />
          </div>
          <div>
            <div className="text-primary-100 font-semibold text-sm">
              Official Tribe
            </div>
            <div className="text-xs text-primary-400 ">0 / 15 Members</div>
          </div>
        </div>
        <div className="md:w-fit flex-shrink-0 pt-4 md:pt-0 w-full">
          <Button
            className="w-fit"
            size={"lg"}
            onClick={() => {
              mutate();
            }}
          >
            Join Tribe
          </Button>
        </div>
      </div>
    </div>
  );
}

export default FeaturedTribe;
