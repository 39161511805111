import { Button } from "../../Component/shared/Button";
import React from "react";
import { z } from "zod";
import { useJoinTribe } from "./useJoinTribe";
import OfficialTribe from "./OfficialTribe";

interface Props {
  tribe: TTribe;
  onJoin: (tribeId: string) => void;
  pending: boolean;
}
const Tribe = z.object({
  _id: z.string(),
  name: z.string(),
  event_id: z.string(),
  event_name: z.string(),
  event_location: z.object({
    type: z.literal("Point"),
    coordinates: z.tuple([z.number(), z.number()]),
  }),
  event_start_date: z.string().datetime(),
  event_end_date: z.string().datetime(),
  tribe_logo: z.string().url(),
  age_group: z.string(),
  is_public: z.boolean(),
  is_location_sharing_enabled: z.boolean(),
  is_deleted: z.boolean(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  __v: z.number(),
  tribeUsers: z.array(z.unknown()),
});
type TTribe = z.infer<typeof Tribe>;

function TribeInfo(props: Props) {
  const { tribe, onJoin, pending } = props;

  return (
    <div className="flex items-center justify-between flex-col gap-4 md:flex-row">
      {tribe.name === "Official Tribe" && (
        <div>
          <OfficialTribe />
        </div>
      )}
      <div className="space-y-2 text-left w-full">
        <div className="text-primary-100 font-semibold text-sm">
          {tribe.name}
        </div>
        <div className="text-xs text-primary-400 ">
          {tribe.tribeUsers.length} / 15 Members
        </div>
      </div>
      <div className="w-full md:text-right">
        <Button
          disabled={pending || tribe.tribeUsers.length >= 15}
          onClick={() => onJoin(tribe._id)}
          className="w-full md:w-fit"
          size={"lg"}
        >
          {tribe.tribeUsers.length === 15 ? "Tribe Full" : "Join Tribe"}
        </Button>
      </div>
    </div>
  );
}

export default TribeInfo;
