import { CheckmarkBadge01Icon } from "hugeicons-react";
import React from "react";

interface Props {}

function OfficialTribe(props: Props) {
  const {} = props;

  return (
    <div className="h-10 w-10 relative rounded-full inline-flex items-center justify-center bg-primary-800">
      <div className="absolute -top-0 -right-2 h-4 inline-flex items-center justify-center  w-4  rounded-full">
        <CheckmarkBadge01Icon className="w-[14px] text-primary-500" />
      </div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_10_7684"
          maskUnits="userSpaceOnUse"
          x="1"
          y="2"
          width="22"
          height="20"
        >
          <path d="M22.5 2H1.5V22H22.5V2Z" fill="white" />
        </mask>
        <g mask="url(#mask0_10_7684)">
          <path
            d="M19.0299 2C19.0299 3.22507 18.0631 4.21963 16.8693 4.21963C15.6755 4.21963 14.7087 3.22637 14.7087 2H11.2383V22L22.499 10.3748V2H19.0287H19.0299Z"
            fill="white"
          />
          <path
            d="M1.5 10.4316V13.9968C2.69251 13.9968 3.66063 14.99 3.66063 16.2164C3.66063 17.4428 2.69377 18.436 1.5 18.436V22.0012H11.2392L9.31938 10.4329H1.5V10.4316Z"
            fill="white"
          />
        </g>
      </svg>
    </div>
  );
}

export default OfficialTribe;
