import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import Backicon from "../../assets/img/Backicon.svg";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
// import Tribesmember from "../../assets/img/Tribesmember.png";
// import Tribesmembertwo from "../../assets/img/Tribesmembertwo.png";
// import Tribesmemberthree from "../../assets/img/Tribesmemberthree.png";
// import Tribesmemberfour from "../../assets/img/Tribesmemberfour.png";
// import TribesmemberFive from "../../assets/img/TribesmemberFive.png";
// import Tribesmembersix from "../../assets/img/Tribesmembersix.png";
// import Tribesmemberseven from "../../assets/img/Tribesmemberseven.png";
// import Tribesmembereight from "../../assets/img/Tribesmembereight.png";
// import Tribesmembernine from "../../assets/img/Tribesmembernine.png";
// import Tribesmemberten from "../../assets/img/Tribesmemberten.png";
// import Tribesmembereleven from "../../assets/img/Tribesmembereleven.png";
import { getTribes, joinTribes, tribeAndUserCountOfEvent } from "../../api/api";
import {
  openUpdatePreferences,
  closeTribe,
  openCreateTribe,
  openSkipTribeModal,
  openMaximumTribesJoinedModal,
  openTribeExitedMaximumTimes,
} from "../../redux/Reducers/modalReducer";
import { toast } from "react-toastify";
import {
  contains2024,
  // findTribeIdByUserId,
  userExistsInTribes,
} from "../../helpers/utils";
import userprofileimage from "../../assets/img/profile-pic.svg";
import VerifyUserInfo from "../../Component/shared/VerifyUserInfo";
import { useQuery } from "@tanstack/react-query";
import TribeInfo from "./TribeInfo";
import { useJoinTribe } from "./useJoinTribe";
import FeaturedTribe from "./FeaturedTribe";
const Tribes = (props) => {
  const { data } = useSelector((state) => state?.model);
  const { user, preferences } = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const [tribe, setTribe] = useState([]);
  const [selectedTribeId, setSelectedTribeId] = useState(null);
  const userLocation = useSelector((state) => state.user.userLocation);
  const navigate = useNavigate();
  const eventId = data.eventId;
  const today = new Date();
  const currentYear = today.getFullYear();
  const { data: tribeData, mutate, status } = useJoinTribe();
  // const [userTribeJoinedId, setUserTribeJoinedId] = useState(null);

  const handleSkip = () => {
    dispatch(openSkipTribeModal());
  };

  useEffect(() => {
    fetchAllTribes();
  }, [userLocation, eventId]);

  const fetchAllTribes = async () => {
    const radius = 10;

    const requestData = {
      event_id: eventId,
      search_query: "",
      page: 1,
      limit: 10,
      sortBy: "createdAt",
      order: -1,
    };

    try {
      const response = await getTribes(requestData);
      console.log(response, "tribe response");
      setTribe(response.data.docs);
      if (response.data.docs.length > 0) {
        setSelectedTribeId(response.data.docs[0]._id);
      }
    } catch (error) {
      console.error("Error fetching tribes:", error);
    }
  };

  const handleSlideChange = (swiper) => {
    const currentSlideIndex = swiper.activeIndex;
    const selectedTribe = tribe[currentSlideIndex];
    setSelectedTribeId(selectedTribe ? selectedTribe._id : null);
  };

  const joinTribe = async () => {
    if (selectedTribeId) {
      try {
        let userExists = userExistsInTribes(tribe, user._id);
        if (userExists) {
          dispatch(openMaximumTribesJoinedModal());
          return;
        }
        const response = await joinTribes({ tribe_id: selectedTribeId });
        console.log(response, "response");
        if (response.statusCode === "400") {
          dispatch(openTribeExitedMaximumTimes());
        } else if (response.statusCode === 201) {
          toast.success(response.message);
          dispatch(closeTribe());
          navigate("/myTribes");
        } else {
          toast.error(response.error);
        }
      } catch (error) {
        console.error("Error joining tribe:", error);
      }
    } else {
      console.error("No tribe selected");
    }
  };

  const handleCreateTribe = () => {
    dispatch(openCreateTribe(data));
    dispatch(closeTribe());
  };

  // const tribe_id = findTribeIdByUserId(tribe, user._id);

  const TRIBE_CAPACITY = 15;
  const allTribesAreFull = tribe.every(
    (tribe) => (tribe?.tribeUsers?.length ?? 0) >= TRIBE_CAPACITY
  );

  return (
    <>
      <Modal
        //    show={isOpen}
        {...props}
        className="sign_in_modal signin_inner_modal categories_modal tribein_modal py-8"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="back_arrow_signin">
              <div className="back_profile_icon">
                <img src={Backicon} alt="logo-icon" className="Backicon" />
              </div>{" "}
              Sign in
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 pb-0">
          <h4>TribeIn</h4>

          {(tribe.length === 0 || allTribesAreFull) && (
            <FeaturedTribe eventId={eventId} />
          )}

          <div className="space-y-12">
            {tribe.map((tribeItem, index) => (
              <TribeInfo
                pending={status === "pending"}
                onJoin={(tribeId) => {
                  console.log(mutate, "tribeId");
                  mutate({ tribeId });
                }}
                tribe={tribeItem}
              />
            ))}
          </div>
          <div className="edit_your_age">
            {/*   <Link
              onClick={() =>
                dispatch(openUpdatePreferences({ eventId: data.eventId }))
              }
            >
              Edit your age and gender preferences
            </Link> */}
          </div>
        </Modal.Body>
        {/*   <Modal.Footer>
          
            <div className="completeyour_profile_btn profile_photo_btn m-0">
              <Button disabled={!allTribesAreFull} onClick={handleCreateTribe} variant="outline-light">
                CREATE A PUBLIC TRIBE
              </Button>
            </div>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Tribes;
