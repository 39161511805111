import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import WelcomeLogo from "../../assets/img/WelcomeLogo.svg";
import Fb from "../../assets/img/Fb.svg";
import Apple from "../../assets/img/Apple.svg";
import Google from "../../assets/img/Google.svg";
import { useDispatch } from "react-redux";
import { closeLogin, closeSignup, openLogin, openPhoneSignIn } from "../../redux/Reducers/modalReducer";
import { Disability01Icon } from "hugeicons-react";

const Login = (props) => {
  const dispatch = useDispatch();
  const handleSignUpWithPhone = () => {
    dispatch(openPhoneSignIn());
  };

  const handleGoogleSignup = () => {
    dispatch(closeSignup());
    dispatch(closeLogin());
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/google/signup`;
  };

  const handleAppleSignup = () => {
    dispatch(closeSignup());
    dispatch(closeLogin());
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/apple/signup`;
  };

  const handleFacebookSignup = () => {
    dispatch(closeSignup());
    dispatch(closeLogin());
    window.location.href = `${process.env.REACT_APP_BASEURL}/auth/facebook/signup`;
  };

  const handleSignup = () => {
    dispatch(closeSignup());
    dispatch(openLogin());
    
  };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal welcome_modal"
        centered
      >
        <Modal.Header closeButton className="p-0">
          <Modal.Title className="m-0"></Modal.Title>
        </Modal.Header>
        <div className="h-full">
          <div className="h-full w-full">

            <div className="relative">

            <div className="bg-black/70 absolute top-0 left-0 w-full h-full rounded-md overflow-hidden">
              </div>
              
            <video
              autoPlay
              loop
              muted
              playsInline={true}
              className="w-full h-screen md:h-full object-cover md:min-h-[90vh] rounded-md"
              >
              <source
                src="https://tribe-in.com/video/Loginvideo.mp4"
                type="video/mp4"
                />
            </video>
                </div>
            {/* <img src={LoginImg} alt="" /> */}
                </div>
          <div className="welcome_logo">
            <div className="flex justify-center">
              <img src={WelcomeLogo} alt="WelcomeLogo" />
            </div>
            <div className="text-md font-medium mb-8">
              <p>Connect. Engage. Experience Live Events Together.</p>
            </div>
            <div className="welcm_btn_list">
              <ul>
                <li>
                  <Button
                    variant="light"
                    className="white_btn text-uppercase"
                    onClick={handleAppleSignup}
                  >
                    <img src={Apple} alt="apple" />
                    Sign {props.prefix} WITH APPLE
                  </Button>
                </li>
                <li>
                  <Button
                    variant="light"
                    className="white_btn text-uppercase"
                    onClick={handleGoogleSignup}
                  >
                    <img src={Google} alt="Google" />
                    Sign {props.prefix} WITH GOOGLE
                  </Button>
                </li>
                <li>
                  <Button
                    variant="light"
                    className="white_btn facebook_btn text-uppercase"
                    onClick={handleFacebookSignup}
                  >
                    <img src={Fb} alt="Facebook" />
                    Sign {props.prefix} WITH FACEBOOK
                  </Button>
                </li>
                <li>
                  <Button
                    variant="light"
                    className="sign_up_with text-uppercase"
                    onClick={handleSignUpWithPhone}
                  >
                    Sign {props.prefix} WITH PHONE NUMBER
                  </Button>
                </li>
              </ul>
            </div>

            <div className="already_have">
              {props.prefix === "up" ? (
                <p className="text-sm">
                  Already have an account?{" "}
                  <Link onClick={handleSignup}>Sign In</Link>
                </p>
              ) : (
                <p className="text-sm">
                  Don't have an account?{" "}
                  <Link
                    onClick={() => {
                      dispatch(closeSignup());
                      dispatch(openLogin());
                    }}
                  >
                    Sign Up
                  </Link>
                </p>
              )}

              <p className="text-sm">
                By signing up for TribeIn, you agree to our{" "}
                <Link
                  to="/termsconditions"
                  onClick={() => dispatch(closeSignup())}
                >
                  terms and conditions
                </Link>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Login;
