import { useQuery } from "@tanstack/react-query";
import { fetchEvents } from "../../api/ticketmaster-events";
import { EventCard } from "../Index/Events/EventCard";

interface Props {}

function Hello(props: Props) {
  const {} = props;

  const query = useQuery({
    queryKey: ["test tes ttest "],
    queryFn: async () => {
      return await fetchEvents({
        category: "festival",
        time_query: "all",
        genre: "All",
        limit: 200,
        location: {
          lat: 30.266666,
          lng: 97.73333,
        },
        within_miles: "999999",
        customDates: {
          start: new Date(),
          end: new Date("2025-05-05"),
        },
      });
    },
  });

  /*   console.log(query.status);
   */ /* 
  if (query.status === "error") {
    return <div>Loading...</div>;
  } */
  /* 
  if (query.isLoading) {
    return <div>Loading...</div>;
  }
 */
  console.log(query);
  console.log(query.data, "here");

  return (
    <div className="grid grid-cols-5 gap-8 max-w-[1600px] mx-auto py-20">
      {query.data?.map((event) => (
        <EventCard event={event} />
      ))}
    </div>
  );
}

export default Hello;
