import { useFormik } from "formik";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Autocomplete from "react-google-autocomplete";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { addUserDetails, getUserByToken } from "../../api/api";
import Backicon from "../../assets/img/Backicon.svg";
import EditPersonal from "../../assets/img/EditPersonal.jpg";
import { BirthInfo } from "../../Component/utils/icon";
import { GENDERS } from "../../helpers/constant";
import { completeProfileValidationSchema } from "../../helpers/validation";
import {
  closeUpdateProflie,
  openUpdatePhoneNumberModal,
} from "../../redux/Reducers/modalReducer";
import { setUser } from "../../redux/Reducers/userReducer";
import { Cancel01Icon } from "hugeicons-react";

export function validateUserProperties(user) {
  const requiredProperties = [
    "first_name",
    "last_name",
    "gender",
    "email",
    "dob",
  ];
  const missingProperties = [];

  for (const prop of requiredProperties) {
    if (
      !user.hasOwnProperty(prop) ||
      user[prop] === null ||
      user[prop] === undefined
    ) {
      missingProperties.push(prop);
    }
  }

  if (missingProperties.length === 0) {
    return { isValid: true, message: "All required properties are present." };
  } else {
    return {
      isValid: false,
      message: `Missing properties: ${missingProperties.join(", ")}`,
      missingProperties: missingProperties,
    };
  }
}


const UpdateProfile = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.user);
  const [stateDisabled, setStateDisabled] = useState({
    phoneInputDisable: false,
    dobInputDisable: false,
  });
  const [captchaToken, setCaptchaToken] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaToken(value);
  };

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      address: {
        name: props.name,
        loc: {
          type: "Point",
          coordinates: [props.lat, props.lng],
        },
      },
      email: "",
      dob: "",
      phone_detail: {
        country_code: "",
        phone_number: "",
      },
      gender: "",
    },

    validationSchema: completeProfileValidationSchema,
    onSubmit: async (values) => {
      console.log({ values });
      await handleSubmit(values);
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      let response = await getUserByToken();

      const hasPhoneNumber = response?.data?.phone_detail?.phone_number;
      if (hasPhoneNumber) {
        setStateDisabled((stateDisabled) => ({
          ...stateDisabled,
          phoneInputDisable: true,
        }));
      }

      if (response?.data?.email) {
        setStateDisabled((stateDisabled) => ({
          ...stateDisabled,
          emailInputDisable: true,
        }));
      }
      if (response?.data?.dob) {
        setStateDisabled((stateDisabled) => ({
          ...stateDisabled,
          dobInputDisable: true,
        }));
      }

      formik.setValues({
        first_name: response?.data?.first_name,
        last_name: response?.data?.last_name,
        address: response?.data?.address,
        email: response?.data?.email,
        dob: response?.data?.dob,
        phone_detail: response?.data?.phone_detail,
        gender: response?.data?.gender,
      });
    };
    fetchData();
  }, []);

  const handlePlace = (place) => {
    if (!place) {
      return;
    }

    console.log(place);
    const address = {
      name: place.formatted_address,
      loc: {
        type: "point",
        coordinates: [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ],
      },
    };

    formik.setFieldValue("address", address);
  };

  const handleGenderChange = (gender) => {
    formik.setFieldValue("gender", gender.value);
  };

  const today = new Date();
  const eighteenYearsAgo = today.getFullYear() - 18;
  const maxDate = new Date(eighteenYearsAgo, today.getMonth(), today.getDate());

  const back = () => {
    dispatch(closeUpdateProflie());
  };

  const handleSubmit = async (values) => {
    try {
      const response = await addUserDetails({
        ...values,
        is_profile_completed: true,
      });
      console.log(response, 'response HIER HEIR IERIER IERIRI');
      if (response.statusCode === 200) {
        toast.success(response?.message);
        dispatch(setUser(response?.data));
        console.log("ASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASDASD")
        dispatch(closeUpdateProflie());
        if (typeof props.onProfileComplete === "function") {
          props.onProfileComplete();
        }
      } else {
        console.log("triggered");
        toast.error(response.error);
      }
    } catch (error) {
      console.log(error, 'DAMN AN ERORROORE!!!!!!!!!!!!!!!!!!!!!!!!!!!')
      console.log("triggered 2 ");
      console.error("Error submitting form: ", error);
      toast.error("An error occurred. Please try again later.");
    } finally {
    }
  };

  const handleUpdatePhoneNumber = () => {
    console.log(" inside");
    dispatch(
      openUpdatePhoneNumberModal({
        userId: user?._id,
        phone_detail: formik.values?.phone_detail,
        token: localStorage.getItem("token"),
      })
    );
    dispatch(closeUpdateProflie());
  };
  console.log(user, "asdsad");
  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="sign_in_modal welcome_modal completeyour_profile"
        centered
      >
        <div className="flex_login_container">
          {validateUserProperties(user).isValid && (
            <button
              className="absolute top-2 right-2 text-white"
              onClick={() => {
                dispatch(closeUpdateProflie());
              }}
            >
              <Cancel01Icon />
            </button>
          )}
          <div className="login_video">
            <div className="login_video_img">
              <img src={EditPersonal} alt="EditPersonal" />
            </div>
            {/* <video autoPlay loop muted className="w-full h-full object-cover" poster="">
              <source src={EventVideo} type="video/mp4" />
            </video> */}
          </div>
          <div className="welcome_logo">
            <div className="completeyour_profile d-none">
              <h2 className="back_arrow_signin">
                <div className="back_profile_icon">
                  <img src={Backicon} alt="logo-icon" className="Backicon" />
                </div>
              </h2>
            </div>
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
            </Modal.Header>
            <div className="personal_info">
              <h2> ✏️ Complete your profile</h2>
              {/* <ProgressBar now={30} />; */}
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="form_row">
                <div
                  className={
                    formik.values.first_name
                      ? "form_row_icon icon_active"
                      : "form_row_icon"
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.89005 1.89667C6.52693 1.89667 5.43005 2.99874 5.43005 4.35667C5.43005 5.67868 6.46277 6.75132 7.76664 6.80923C7.84571 6.80374 7.92925 6.80346 8.00806 6.80918C9.31065 6.75065 10.344 5.6787 10.3501 4.35562C10.3495 2.99891 9.2469 1.89667 7.89005 1.89667ZM4.43005 4.35667C4.43005 2.44793 5.97318 0.896667 7.89005 0.896667C9.79953 0.896667 11.3501 2.44719 11.3501 4.35667V4.35875C11.3423 6.22568 9.87069 7.74729 8.01352 7.80972C7.99131 7.81047 7.96908 7.80973 7.94697 7.80752C7.91482 7.8043 7.86678 7.80388 7.82199 7.80795C7.80136 7.80982 7.78063 7.81041 7.75992 7.80972C5.90312 7.7473 4.43005 6.22558 4.43005 4.35667Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.00504 8.35498C9.2904 8.35498 10.6012 8.67754 11.6142 9.35403C12.5249 9.96012 13.0467 10.8098 13.0467 11.7241C13.0467 12.6384 12.5251 13.4895 11.6148 14.0988C10.5987 14.7787 9.28609 15.1033 8.00004 15.1033C6.71398 15.1033 5.40147 14.7788 4.38532 14.0989C3.47495 13.4928 2.95337 12.6433 2.95337 11.7291C2.95337 10.8149 3.475 9.96381 4.38523 9.35448L4.38706 9.35326C5.40602 8.67764 6.71941 8.35498 8.00504 8.35498ZM4.94061 10.1861C4.23816 10.6566 3.95337 11.2252 3.95337 11.7291C3.95337 12.2329 4.23804 12.7998 4.94032 13.267L4.94142 13.2678C5.75861 13.8145 6.86609 14.1033 8.00004 14.1033C9.13394 14.1033 10.2414 13.8146 11.0586 13.2678M4.94061 10.1861C5.76156 9.6421 6.87109 9.35498 8.00504 9.35498C9.13957 9.35498 10.2454 9.64237 11.059 10.1858C11.7613 10.653 12.0467 11.2203 12.0467 11.7241C12.0467 12.2282 11.7616 12.7972 11.0586 13.2678"
                      fill="#E6E9FF"
                    />
                  </svg>
                </div>
                <Form.Control
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <small className="text-danger">
                    {formik.errors.first_name}
                  </small>
                )}
              </div>
              <div className="form_row">
                <div
                  className={
                    formik.values.last_name
                      ? "form_row_icon icon_active"
                      : "form_row_icon"
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.89005 1.89667C6.52693 1.89667 5.43005 2.99874 5.43005 4.35667C5.43005 5.67868 6.46277 6.75132 7.76664 6.80923C7.84571 6.80374 7.92925 6.80346 8.00806 6.80918C9.31065 6.75065 10.344 5.6787 10.3501 4.35562C10.3495 2.99891 9.2469 1.89667 7.89005 1.89667ZM4.43005 4.35667C4.43005 2.44793 5.97318 0.896667 7.89005 0.896667C9.79953 0.896667 11.3501 2.44719 11.3501 4.35667V4.35875C11.3423 6.22568 9.87069 7.74729 8.01352 7.80972C7.99131 7.81047 7.96908 7.80973 7.94697 7.80752C7.91482 7.8043 7.86678 7.80388 7.82199 7.80795C7.80136 7.80982 7.78063 7.81041 7.75992 7.80972C5.90312 7.7473 4.43005 6.22558 4.43005 4.35667Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.00504 8.35498C9.2904 8.35498 10.6012 8.67754 11.6142 9.35403C12.5249 9.96012 13.0467 10.8098 13.0467 11.7241C13.0467 12.6384 12.5251 13.4895 11.6148 14.0988C10.5987 14.7787 9.28609 15.1033 8.00004 15.1033C6.71398 15.1033 5.40147 14.7788 4.38532 14.0989C3.47495 13.4928 2.95337 12.6433 2.95337 11.7291C2.95337 10.8149 3.475 9.96381 4.38523 9.35448L4.38706 9.35326C5.40602 8.67764 6.71941 8.35498 8.00504 8.35498ZM4.94061 10.1861C4.23816 10.6566 3.95337 11.2252 3.95337 11.7291C3.95337 12.2329 4.23804 12.7998 4.94032 13.267L4.94142 13.2678C5.75861 13.8145 6.86609 14.1033 8.00004 14.1033C9.13394 14.1033 10.2414 13.8146 11.0586 13.2678M4.94061 10.1861C5.76156 9.6421 6.87109 9.35498 8.00504 9.35498C9.13957 9.35498 10.2454 9.64237 11.059 10.1858C11.7613 10.653 12.0467 11.2203 12.0467 11.7241C12.0467 12.2282 11.7616 12.7972 11.0586 13.2678"
                      fill="#E6E9FF"
                    />
                  </svg>
                </div>
                <Form.Control
                  type="text"
                  placeholder=""
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {!formik?.values?.last_name && (
                  <div class="placeholder">
                    Last Name <span>(Optional)</span>
                  </div>
                )}
                {formik.touched.last_name && formik.errors.last_name && (
                  <small className="text-danger">
                    {formik.errors.last_name}
                  </small>
                )}
              </div>
              <div className="form_row">
                <div
                  className={
                    formik?.values?.address?.name
                      ? "form_row_icon icon_active"
                      : "form_row_icon"
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.99993 5.29515C7.12732 5.29515 6.41993 6.00254 6.41993 6.87515C6.41993 7.74776 7.12732 8.45515 7.99993 8.45515C8.87254 8.45515 9.57993 7.74776 9.57993 6.87515C9.57993 6.00254 8.87254 5.29515 7.99993 5.29515ZM5.41993 6.87515C5.41993 5.45026 6.57504 4.29515 7.99993 4.29515C9.42483 4.29515 10.5799 5.45026 10.5799 6.87515C10.5799 8.30005 9.42483 9.45515 7.99993 9.45515C6.57504 9.45515 5.41993 8.30005 5.41993 6.87515Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.00207 1.83515C5.72421 1.83361 3.49626 3.1554 2.90082 5.77254C2.19619 8.88598 4.12533 11.586 5.94005 13.335C7.09372 14.4425 8.90704 14.4411 10.0527 13.3354L10.0536 13.3345C11.8749 11.5855 13.8037 8.8921 13.0989 5.77887C12.5066 3.16164 10.28 1.83669 8.00207 1.83515ZM14.0742 5.55808C13.3599 2.402 10.6564 0.836939 8.00274 0.835146C5.34899 0.833352 2.64364 2.39483 1.92569 5.5509C1.09722 9.21057 3.3811 12.2576 5.24627 14.0552C6.78582 15.5337 9.2125 15.5357 10.7467 14.0553C12.6187 12.2575 14.9027 9.21786 14.0742 5.55808Z"
                      fill="#E6E9FF"
                    />
                  </svg>
                </div>
                <Autocomplete
                  apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
                  style={{ width: "100%", height: "48px" }}
                  onPlaceSelected={handlePlace}
                  defaultValue={formik?.values?.address?.name}
                  name="address.name"
                  onBlur={formik.handleBlur}
                />
                {formik.touched?.address?.name &&
                  formik.errors?.address?.name && (
                    <small className="text-danger">
                      {formik.errors?.address?.name}
                    </small>
                  )}
              </div>
              <div className="form_row">
                <div
                  className={
                    formik.values.email
                      ? "form_row_icon icon_active"
                      : "form_row_icon"
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M2.59027 3.4914C2.13696 3.91232 1.83337 4.5953 1.83337 5.66667V10.3333C1.83337 11.4047 2.13696 12.0877 2.59027 12.5086C3.05028 12.9358 3.74335 13.1667 4.66671 13.1667H11.3334C12.2567 13.1667 12.9498 12.9358 13.4098 12.5086C13.8631 12.0877 14.1667 11.4047 14.1667 10.3333V5.66667C14.1667 4.5953 13.8631 3.91232 13.4098 3.4914C12.9498 3.06425 12.2567 2.83334 11.3334 2.83334H4.66671C3.74335 2.83334 3.05028 3.06425 2.59027 3.4914ZM1.90982 2.75861C2.61647 2.10243 3.59006 1.83334 4.66671 1.83334H11.3334C12.41 1.83334 13.3836 2.10243 14.0903 2.75861C14.8036 3.42102 15.1667 4.4047 15.1667 5.66667V10.3333C15.1667 11.5953 14.8036 12.579 14.0903 13.2414C13.3836 13.8976 12.41 14.1667 11.3334 14.1667H4.66671C3.59006 14.1667 2.61647 13.8976 1.90982 13.2414C1.19645 12.579 0.833374 11.5953 0.833374 10.3333V5.66667C0.833374 4.4047 1.19645 3.42102 1.90982 2.75861Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.724 5.68796C11.8963 5.90373 11.8611 6.21834 11.6454 6.39068L9.55868 8.05735C8.68976 8.74912 7.30415 8.74961 6.43522 8.05784L6.43399 8.05686L4.35399 6.39019C4.1385 6.21752 4.10378 5.90285 4.27645 5.68735C4.44913 5.47185 4.7638 5.43714 4.9793 5.60981L7.05806 7.2755V7.2755C7.56246 7.67655 8.4307 7.67691 8.93496 7.27571V7.27571L11.0213 5.60933C11.237 5.43699 11.5517 5.4722 11.724 5.68796Z"
                      fill="#E6E9FF"
                    />
                  </svg>
                </div>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email && (
                  <small className="text-danger">{formik.errors.email}</small>
                )}
              </div>
              <div
                className={
                  stateDisabled.dobInputDisable
                    ? "form_row is_grey"
                    : "form_row"
                }
              >
                <div
                  className={
                    formik.values.dob
                      ? "form_row_icon icon_active"
                      : "form_row_icon"
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.33337 0.833332C5.60952 0.833332 5.83337 1.05719 5.83337 1.33333V3.33333C5.83337 3.60947 5.60952 3.83333 5.33337 3.83333C5.05723 3.83333 4.83337 3.60947 4.83337 3.33333V1.33333C4.83337 1.05719 5.05723 0.833332 5.33337 0.833332Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.6666 0.833332C10.9428 0.833332 11.1666 1.05719 11.1666 1.33333V3.33333C11.1666 3.60947 10.9428 3.83333 10.6666 3.83333C10.3905 3.83333 10.1666 3.60947 10.1666 3.33333V1.33333C10.1666 1.05719 10.3905 0.833332 10.6666 0.833332Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.83337 6.06C1.83337 5.78385 2.05723 5.56 2.33337 5.56H13.6667C13.9429 5.56 14.1667 5.78385 14.1667 6.06C14.1667 6.33614 13.9429 6.56 13.6667 6.56H2.33337C2.05723 6.56 1.83337 6.33614 1.83337 6.06Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M3.15806 3.59022C2.73091 4.05023 2.5 4.74331 2.5 5.66667V11.3333C2.5 12.2567 2.73091 12.9498 3.15806 13.4098C3.57899 13.8631 4.26197 14.1667 5.33333 14.1667H10.6667C11.738 14.1667 12.421 13.8631 12.8419 13.4098C13.2691 12.9498 13.5 12.2567 13.5 11.3333V5.66667C13.5 4.74331 13.2691 4.05023 12.8419 3.59022C12.421 3.13692 11.738 2.83333 10.6667 2.83333H5.33333C4.26197 2.83333 3.57899 3.13692 3.15806 3.59022ZM2.42527 2.90977C3.08768 2.19641 4.07137 1.83333 5.33333 1.83333H10.6667C11.9286 1.83333 12.9123 2.19641 13.5747 2.90977C14.2309 3.61643 14.5 4.59002 14.5 5.66667V11.3333C14.5 12.41 14.2309 13.3836 13.5747 14.0902C12.9123 14.8036 11.9286 15.1667 10.6667 15.1667H5.33333C4.07137 15.1667 3.08768 14.8036 2.42527 14.0902C1.76909 13.3836 1.5 12.41 1.5 11.3333V5.66667C1.5 4.59002 1.76909 3.61643 2.42527 2.90977Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.96313 9.13334C9.96313 8.8572 10.187 8.63334 10.4631 8.63334H10.4691C10.7453 8.63334 10.9691 8.8572 10.9691 9.13334C10.9691 9.40948 10.7453 9.63334 10.4691 9.63334H10.4631C10.187 9.63334 9.96313 9.40948 9.96313 9.13334Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M9.96313 11.1333C9.96313 10.8572 10.187 10.6333 10.4631 10.6333H10.4691C10.7453 10.6333 10.9691 10.8572 10.9691 11.1333C10.9691 11.4095 10.7453 11.6333 10.4691 11.6333H10.4631C10.187 11.6333 9.96313 11.4095 9.96313 11.1333Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.49707 9.13334C7.49707 8.8572 7.72093 8.63334 7.99707 8.63334H8.00306C8.2792 8.63334 8.50306 8.8572 8.50306 9.13334C8.50306 9.40948 8.2792 9.63334 8.00306 9.63334H7.99707C7.72093 9.63334 7.49707 9.40948 7.49707 9.13334Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.49707 11.1333C7.49707 10.8572 7.72093 10.6333 7.99707 10.6333H8.00306C8.2792 10.6333 8.50306 10.8572 8.50306 11.1333C8.50306 11.4095 8.2792 11.6333 8.00306 11.6333H7.99707C7.72093 11.6333 7.49707 11.4095 7.49707 11.1333Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.02954 9.13334C5.02954 8.8572 5.2534 8.63334 5.52954 8.63334H5.53553C5.81167 8.63334 6.03553 8.8572 6.03553 9.13334C6.03553 9.40948 5.81167 9.63334 5.53553 9.63334H5.52954C5.2534 9.63334 5.02954 9.40948 5.02954 9.13334Z"
                      fill="#E6E9FF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.02954 11.1333C5.02954 10.8572 5.2534 10.6333 5.52954 10.6333H5.53553C5.81167 10.6333 6.03553 10.8572 6.03553 11.1333C6.03553 11.4095 5.81167 11.6333 5.53553 11.6333H5.52954C5.2534 11.6333 5.02954 11.4095 5.02954 11.1333Z"
                      fill="#E6E9FF"
                    />
                  </svg>
                </div>
                <div className="info_date_picker">
                  <DatePicker
                    disabled={stateDisabled.dobInputDisable}
                    dateFormat="MM/dd/yyyy"
                    selected={formik.values.dob}
                    onChange={(d) => {
                      formik.setFieldValue("dob", d);
                    }}
                    minDate={new Date(1960, 0, 1)}
                    onBlur={formik.handleBlur}
                    todayButton={"Today"}
                    popperPlacement="top-start"
                    showYearDropdown
                    showMonthDropdown
                    scrollableYearDropdown // Optional: make the year dropdown scrollable
                    yearDropdownItemNumber={500} // Optional: number of years shown in the dropdown
                  />
                  {["bottom"].map((placement) => (
                    <OverlayTrigger
                      rlayTrigger
                      key={placement}
                      placement={placement}
                      overlay={
                        <Tooltip
                          id={`tooltip-${placement}`}
                          className="tool_tip"
                        >
                          Date of birth cannot be modified later.
                        </Tooltip>
                      }
                    >
                      <span
                        className="info-img"
                        style={{
                          position: "absolute",
                          top: "12px",
                          right: "18px",
                        }}
                      >
                        <BirthInfo />
                      </span>
                    </OverlayTrigger>
                  ))}
                </div>
                {formik.touched.dob && formik.errors.dob && (
                  <small className="text-danger">{formik.errors.dob}</small>
                )}
              </div>
              <div className="form_row">
                <div
                  className={
                    formik.values.gender
                      ? "form_row_icon icon_active"
                      : "form_row_icon"
                  }
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.2294 1.48587C10.3313 1.73256 10.3736 2.0277 10.3736 2.3466V6.6466H11.9336C12.463 6.6466 13.0341 6.80856 13.2643 7.31831C13.4946 7.82824 13.2379 8.36272 12.8885 8.75748V8.75748L7.84227 14.4903C7.63159 14.7295 7.40477 14.9231 7.16552 15.0411C6.92414 15.1601 6.63109 15.2188 6.34039 15.1097C6.04941 15.0005 5.86748 14.7631 5.76454 14.514C5.66261 14.2673 5.62029 13.9722 5.62029 13.6533V9.35327H4.06029C3.53462 9.35327 2.96538 9.19324 2.73488 8.68468C2.50489 8.17723 2.75774 7.64297 3.10268 7.24553L3.10497 7.2429L8.15164 1.50957C8.36233 1.27042 8.58914 1.0768 8.8284 0.958801C9.06977 0.839753 9.36283 0.781033 9.65353 0.89017C9.9445 0.99941 10.1264 1.23676 10.2294 1.48587ZM8.90214 2.17045V2.17045L3.85684 7.90222C3.73476 8.04314 3.67956 8.15124 3.65865 8.21894C3.65077 8.24446 3.64851 8.26107 3.64807 8.2706C3.65539 8.27635 3.66897 8.2852 3.69243 8.2956C3.75644 8.324 3.87371 8.35327 4.06029 8.35327H6.12029C6.39643 8.35327 6.62029 8.57713 6.62029 8.85327V13.6533C6.62029 13.8944 6.65381 14.0476 6.68875 14.1321C6.69242 14.141 6.69589 14.1487 6.69909 14.1552C6.7061 14.1523 6.71412 14.1487 6.72319 14.1442C6.80581 14.1035 6.93246 14.0103 7.09178 13.8294L12.1383 8.09624L12.1393 8.09509C12.263 7.95542 12.3188 7.848 12.3398 7.7811C12.3476 7.7564 12.3499 7.74025 12.3505 7.73092C12.343 7.725 12.3291 7.71582 12.3048 7.70503C12.24 7.67621 12.1216 7.6466 11.9336 7.6466H9.87362C9.59748 7.6466 9.37362 7.42274 9.37362 7.1466V2.3466C9.37362 2.1055 9.34011 1.95232 9.30517 1.86776C9.30149 1.85886 9.29803 1.85121 9.29483 1.84464C9.28782 1.84756 9.2798 1.85118 9.27073 1.85565C9.18813 1.89639 9.0614 1.98969 8.90214 2.17045ZM12.3504 7.72309L12.35 7.7207ZM3.64816 8.27849L3.64857 8.28083Z"
                      fill="#E6E9FF"
                    />
                  </svg>
                </div>
                <Select
                  id="gender"
                  name="gender"
                  placeholder="Gender"
                  value={GENDERS.find(
                    (gender) => gender.value === formik.values.gender
                  )}
                  onChange={handleGenderChange}
                  onBlur={formik.handleBlur}
                  options={GENDERS}
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
                {formik.touched.gender && formik.errors.gender && (
                  <small className="text-danger">{formik.errors.gender}</small>
                )}
              </div>
              <ReCAPTCHA
                ref={null}
                sitekey={process.env.REACT_APP_SITE_KEY}
                size="normal"
                onChange={handleCaptchaChange}
              />
              <div className="completeyour_profile_btn">
                {console.log(Object.keys(formik.errors).length, captchaToken)}
                <Button
                  variant="secondary"
                  type="button"
                  className={
                    Object.keys(formik.errors).length || !captchaToken
                      ? "btn-secondary"
                      : "is_btn_active"
                  }
                  onClick={formik.handleSubmit}
                  disabled={!captchaToken}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default UpdateProfile;
